@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@300;500&display=swap);
:root {
  --main-accent: #E0B0D5;
  --faded-accent: #E5D0E3;
  --dark-accent: #19647E;
  --bright-accent: #AEE5D8;
  --dark-main: #37392E;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Global */

:root {
  --main-accent: #E0B0D5;
  --faded-accent: #E5D0E3;
  --dark-accent: #19647E;
  --bright-accent: #AEE5D8;
  --dark-main: #37392E;
  font-family: Heebo;
  font-weight: 300;
  overflow:hidden;
}

body {
  overflow: hidden;
  font-size: 18px;
  color: #37392E;
  color: var(--dark-main);
  font-family: Heebo;
}

#container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

}

#fullCard {
  float: left;
  display: block;
  clear: none;
  position: relative;
  margin-bottom: 0px;
  padding: 10px;
  padding-left: 20%;
  padding-right: 20%;
  text-align: justify;
  line-height: 1.5em;
  width: 60%;
  float: left;
  height: auto;
  padding-bottom: 150px;
  z-index: 1000;
  overflow:fixed;
}

#fullCard img {
  width: 100%;
  max-width: 400px;
  float: right;
  padding: 15px;
  padding-right: 0px;
}

#fullCard hr {
  clear: both;
  justify-content: center;
  border: 0px;
  border-top: #f0f0f0 0.5px solid;
}

#fullCard  h2 {
  color: #37392E;
  color: var(--dark-main);
  font-weight: 300;
  font-size: 30px;
  font-family: Heebo;
}

#fullCard  h3 {
  color: #AEE5D8;
  color: var(--bright-accent);
  font-family: Heebo;
}

#fullCard a{
  color: #37392E;
  color: var(--dark-main);
  text-decoration: none;
  background-color: #AEE5D8;
  background-color: var(--bright-accent);
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -ms-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
}

#fullCard a:hover{
  color: #37392E;
  color: var(--dark-main);
  text-decoration: none;
  background-color: #E0B0D5;
  background-color: var(--main-accent);
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -ms-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
}


/* Header Default */
#nicknames {
  width: 200px;
  float: left;
}

#nicknames h1 {
  color: #E0B0D5;
  color: var(--main-accent);
  font-size:40px;
  line-height: 30px;
  vertical-align: middle;
  font-family: Heebo;
  font-weight: 300;
  padding-top: 5px;
}

#nicknames h2 {
  color: lightgrey;
  font-size:40px;
  line-height: 30px;
  vertical-align: middle;
  font-family: Heebo;
  font-weight: 300;
}

/* Web */
#headerWeb .webActive {
  border-bottom: #E0B0D5 3px solid;
  border-bottom: var(--main-accent) 3px solid;
}

#headerWeb {
  height: 20%;
  min-height: 100px;
  background-color: white;
  width: 100%;
  padding: 1%;
}

#headerWeb img {
  max-height: 20px;
  object-fit: contain;
}

 #headerWeb button {
  outline: none;
  font-family: Heebo;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  border: 0px;
  margin-top:26px;
  position: static;
  float: left;
  padding: 10px;
  color: #37392E;
  color: var(--dark-main);
  text-decoration: none;
  font-family: Heebo;
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -ms-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
}

#headerWeb button:hover{
  border-bottom: #E0B0D5 3px solid;
  border-bottom: var(--main-accent) 3px solid;
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -ms-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
}

#headerWeb a {
  margin-top:26px;
  position: static;
  float: left;
  padding: 10px;
  color: #37392E;
  color: var(--dark-main);
  text-decoration: none;
  font-family: Heebo;
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -ms-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
}

#headerWeb a:hover{
  border-bottom: #E0B0D5 3px solid;
  border-bottom: var(--main-accent) 3px solid;
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -ms-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
}

#carousel {
  width: 100%;
  height: 40%;
  min-height: 250px;
  position: relative;
  padding: 0px;
  float:left;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  z-index: 100;
}

#carousel img {
  width: 20%;
  object-position: 40% 0;
  padding-right: 5px;
  vertical-align: top;
  float: start;
  min-height: 110%;
  flex-wrap: nowrap;
  object-fit: cover;
  z-index: 1;
  min-width: 300px;
  overflow: hidden;
}

#carousel img:last-child {
  padding-right: 0px;
}

#infoBox {
  background-color: white;
  height: 25%px;
  width: auto;
  min-width: 300px;
  z-index: 100;
  position: absolute;
  color: var(--main-dark);
  bottom: 20px;
  margin-left: 20px;
  padding: 10px;
  padding-right: 30px;
  float: left;
}

#infoBox h1 {
  line-height: 15px;
  font-weight: 500;
  font-size: 30px;
  background-size: 70%;
  background-color: #E0B0D5;
  background-color: var(--main-accent)
}

#infoBox p {
  font-size: 20px;
}

#pageBio {
  width: 95%;
  height: auto;
  position: relative;
  padding: 10px;
  padding-bottom: 50px;
  padding-left: 2%;
  padding-right: 2%;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
  float:left;
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: normal;
}

#pageBio hr {
  width: 90%;
  margin-left: 0px;
  border-top: #E0B0D5 3px solid;
  border-top: var(--main-accent) 3px solid;
  bottom: 20px;
  position: absolute;
  background-color: white;
  overflow:none;
}

#pageBio h1 {
  font-weight: 500;
  font-size: 30px;
  background-size: 50%;
  font-family: Heebo;
}

#pageBio span{
  line-height: 15px;
  position: static;
  float:left;
  background-color: #E0B0D5;
  background-color: var(--main-accent);
}

#pageBio p {
  font-size: 18px;
  overflow-wrap: break-word;
}


/* Mobile */
#headerMobile .mobileActive {
  border-left: #E0B0D5 3px solid;
  border-left: var(--main-accent) 3px solid;
}

#headerMobile {
  height: 20%;
  min-height: 100px;
  background-color: white;
  width: 100%;
  display: flex;
}

#headerMobile button {
  background-color: white;
  float: top;
  position: relative;
  font-size: 1em;
  border: 0px;
  font-weight: 300;

}

#headerMobile img {
  max-height: 20px;
  object-fit: contain;
}

#headerMobile button:hover {
  border-left: lightgrey 3px solid;
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -ms-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;

}

#headerMobile #nicknames {
  margin-left: 60px;
}

/* Hamburger menu */

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 33px;
}


/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #E5D0E3;
  background: var(--faded-accent);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1.5px solid white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: lightgrey;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #37392E;
  background: var(--dark-main);
  overflow: hidden;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow-y: hidden;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: lightgrey);
  padding: 0.8em;
  overflow: hidden;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-top:2px;
  position: static;
  padding: 10px;
  color: #37392E;
  color: var(--dark-main);
  text-decoration: none;
  font-family: Heebo;
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -ms-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
}

.bm-item:focus {
  outline:none;
}

.bm-item:hover {
   border-left: lightgrey 3px solid;
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -ms-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0,0,0,0.3);
}
